<template>
    <vue-plotly style="width:100%;" :data="data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'SimpleBarChartV',
  components: { VuePlotly },
  props: {
    x: Array,
    y: Array,
    ytitle: {
      type: String,
      default: ''
    },
    xtitle: {
      type: String,
      default: ''
    },
    tickangle: {
      type: Number,
      default: 0
    },
    tickfont_size:{
      type: Number,
      default:10
    },
    marginBottom: {
      type: Number,
      default: 140
    }
    ,
    marginTop: {
      type: Number,
      default: 50
    }
    ,
    marginRight: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      layout: {
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        height: 400,
        margin: {
          l: 50,
          r: this.marginRight,
          b: this.marginBottom,
          t: this.marginTop,
          pad: 4},
        xaxis: {
          automargin:true,
          tickangle: this.tickangle,
          tickfont:{
            size:this.tickfont_size
          },
          title: {
            standoff:20,
            text: this.xtitle,
            font: {
              family: 'Gotham',
              size: 14
            },
          }
        },
        yaxis: {
          automargin:true,
          title: {
            standoff:20,
            text: this.ytitle,
            font: {
              family: 'Gotham',
              size: 14
            },
          },
          ticksuffix:'%'
        }
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    data () {
      return [
        {
          type: 'bar',
          x: this.x,
          y: this.y,
          text:this.y.map(d=>(d).toFixed(0)),
          textposition: 'inside',
          hoverinfo: 'none',
          orientation: 'v',
          marker: {
            color: 'rgba(140, 140, 140,1.0)'
          }
        },
      ]
    }
  }
}

</script>
